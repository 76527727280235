import { createContext, useEffect, useState } from 'react';
import { getData, storeData } from './async-storage';
import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import Moment from 'react-moment';
import moment from 'moment'
import * as Sentry from 'sentry-expo';

export const UserContext = createContext({
  user : null,
  notifications: [],
  clockReminder: null,
  updateClockReminder: () => {},
  scheduleNotifications: (user) => {},
  updateNotifications: (notification) => {},
  replaceNotifications: (notifications) => {},
});

function UserContextProvider({ children }) {
  const [clockReminder, setClockReminder] = useState(true);
  const [user, setUser] = useState(null);
  const [notifications, setNotifications] = useState([])

  const days = {
    sunday: 1,
    monday: 2,
    tuesday: 3,
    wednesday: 4,
    thursday: 5,
    friday: 6,
    saturday: 7
}

  useEffect(() => {
    try {
      fetchClockReminder()
      fetchNotifications()  
    } catch (error) {
      Sentry.Native.captureException(error) 
    }
  }, [])

  useEffect(() => {
    try {
      if (user === null)
        return;

      console.log('CLOCK REMINDER CHANGED TO ' + clockReminder)

      scheduleNotifications(user)
    } catch (error) {
      Sentry.Native.captureException(error)
    }
  }, [clockReminder])

  async function fetchClockReminder() {
    const store = await getData('clockReminder')

    if (store !== null) 
      setClockReminder(store)
  }

  async function updateClockReminder() {
    storeData('clockReminder', !clockReminder)
    setClockReminder(!clockReminder)
  }

  async function fetchNotifications(){
    const notifications = await getData('notifications')

    if (notifications !== null)
      setNotifications(notifications)
  }

  async function updateNotifications(notification){
    notification.viewed = false

    storeData('notifications', [...notifications, notification])
    setNotifications((notifications) => [...notifications, notification])
  }

  async function replaceNotifications(notifications){
    storeData('notifications', notifications)
    setNotifications(notifications)
  }
 
  async function scheduleNotifications(user) {
    if (Platform.OS === 'web') //Web does not support Notifications
        return;

    Notifications.cancelAllScheduledNotificationsAsync()

    if (clockReminder && user.shift !== undefined)
    {
      user.shift.shift_days.forEach(day => {
        const fromDate = moment(user.shift.shift_time.from)
        const toDate = moment(user.shift.shift_time.to)
        const fromTime = moment(user.shift.shift_time.from).format("HH:mm")
        const toTime = moment(user.shift.shift_time.to).format("HH:mm")

        console.log(day + ' - ' + fromTime + ' - ' + toTime)

        Notifications.scheduleNotificationAsync({
          content: {
            title: "Shift starting now! 💼",
            body: 'Your shift goes from ' + fromTime + ' to ' + toTime,
          },
          trigger: { 
            hour: fromDate.hours(),
            minute: fromDate.minutes(),
            weekday: days[day],
            repeats: true,
           },
        });

        Notifications.scheduleNotificationAsync({
          content: {
            title: "Shift ending now! 😴",
            body: 'Your shift goes from ' + fromTime + ' to ' + toTime,
          },
          trigger: { 
            hour: toDate.hours(),
            minute: toDate.minutes(),
            weekday: days[day],
            repeats: true,
           },
        });
      })
    }
  }

  const value = {
    user: user,
    notifications: notifications,
    clockReminder: clockReminder,
    setUser: setUser,
    updateClockReminder: updateClockReminder,
    scheduleNotifications: scheduleNotifications,
    updateNotifications: updateNotifications,
    replaceNotifications: replaceNotifications
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
