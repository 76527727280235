import { useContext, useEffect, useState } from 'react';
import { useTheme, FAB, Provider, Portal, AnimatedFAB, Avatar,ActivityIndicator } from 'react-native-paper';
import { StyleSheet, View, Text, KeyboardAvoidingView, Platform, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons'
import * as Animatable from 'react-native-animatable'
import Modal from "react-native-modal";

import IconButton from './IconButton';
import Button from './Button';
import ClockReceiptModal from './ClockReceiptModal';

import { Camera, CameraType } from 'expo-camera';
import * as FaceDetector from 'expo-face-detector';

import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';

import { SIZES, FONTS } from '../../constants/theme'
import { Colors } from '../../constants/styles';

function ClockModal({ isVisible, setIsModalVisible, isStart, enableCamera, hasCameraPermission, handleClockIn, cameraRef, loadingStatus }) {
    const theme = useTheme();
    const authCtx = useContext(AuthContext);
    const companyCtx = useContext(CompanyContext);
    const [facesDetected, setFacesDetected] = useState([])
    const [isModalReceiptVisible, setIsModalReceiptVisible] = useState(false);
    const [isReceiptClockIn, setIsReceiptClockIn] = useState(!isStart)
    const [record, setRecord] = useState(null)

    function handleFacesDetected(faceResult){
        console.log(faceResult)
        setFacesDetected(faceResult.faces)
    }

    return (
        <Modal
            isVisible={isVisible}
            onBackdropPress={() => { setIsModalVisible(false), setIsModalReceiptVisible(false)}}
            animationIn={"zoomIn"}
            animationOut={"zoomOut"}
            onModalHide={() => { setIsModalReceiptVisible(false) }}
            hideModalContentWhileAnimating={true}
            //useNativeDriver={true}
        >
            <View
                style={{
                    flex: enableCamera ? 1 : 0.5,
                    minHeight: 50,
                    backgroundColor: theme.colors.background,
                    marginVertical: SIZES.padding * 3,
                    marginHorizontal: SIZES.padding * 1,
                    borderRadius: SIZES.radius,
                }}
            >
                {/* HEADER */}
                <View
                    style={{
                        height: 100,
                        backgroundColor: theme.colors.tertiary,
                        alignItems: 'center',
                        borderTopEndRadius: SIZES.radius,
                        borderTopStartRadius: SIZES.radius,
                    }}>
                    <View
                        style={{
                            flex: 1,
                            width: '100%',
                            alignItems: 'flex-end',
                            paddingHorizontal: SIZES.base,
                            paddingTop: SIZES.base,
                        }}
                    >
                        <IconButton
                            icon="close-circle-outline"
                            size={30}
                            color={'white'}
                            onPress={() => { setIsModalVisible(false), setIsModalReceiptVisible(false)}}
                        />
                    </View>

                    <View
                        style={{
                            flex: 1,
                            alignItems: 'center',
                            top: -10
                        }}
                    >
                        {!!authCtx.user?.attributes.picture ?
                            <Avatar.Image size={90} source={{ uri: authCtx.user?.attributes.picture }} />
                            :
                            <Avatar.Icon 
                                size={90} 
                                style={{
                                    borderColor: 'white',
                                    borderWidth: 4,
                                }} 
                                icon="account" 
                            />
                        }

                        <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h2 }}>{authCtx.user?.attributes.given_name + ' ' + authCtx.user?.attributes.family_name}</Text>
                    </View>
                </View>

                {/* BODY */}
                <View
                    style={{
                        flex: 1,
                    }}>
                    {enableCamera ?
                        <View
                            style={{
                                marginTop: SIZES.padding * 5,
                                marginHorizontal: SIZES.padding,
                                borderRadius: SIZES.radius,
                                overflow: 'hidden',
                                flex: 1,
                                // FOR FACE DETECTION
                                //borderWidth: 7,
                                //borderColor: facesDetected.length > 0 ? theme.colors.success : theme.colors.error
                            }}
                        >

                            {hasCameraPermission ?
                                <Camera
                                    // FOR FACE DETECTION
                                    //onFacesDetected={handleFacesDetected}
                                    // faceDetectorSettings={{
                                    //     mode: FaceDetector.FaceDetectorMode.fast,
                                    //     detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
                                    //     runClassifications: FaceDetector.FaceDetectorClassifications.none,
                                    //     minDetectionInterval: 200,
                                    //     tracking: false,
                                    // }}
                                    type={
                                        Camera.Constants.Type.front
                                    }
                                    playSoundOnCapture={true}
                                    useCamera2Api={true}
                                    ref={cameraRef}
                                    style={{
                                        flex: 1,
                                        borderRadius: SIZES.radius
                                    }}
                                >
                                </Camera>
                                :
                                <Text>
                                    {'You need permission to use the camera'}
                                </Text>
                            }
                        </View>
                        :
                        <></>
                    }

                </View>


                {/* FOOTER */}
                <View
                    style={{
                        padding: SIZES.padding,
                    }}>

                    <View
                        style={{
                            bottom: '5%',
                        }}
                    >
                        <Button
                            // FOR FACE DETECTION
                            //disabled={!enableCamera || (enableCamera && facesDetected.length > 0) ? false : true}
                            // customStyle={{
                            //     backgroundColor: !enableCamera || (enableCamera && facesDetected.length > 0) ? isStart ? theme.colors.error : theme.colors.success : theme.colors.onSurfaceDisabled
                            // }}
                            customStyle={{
                                backgroundColor: isStart ? theme.colors.error : theme.colors.success
                            }}
                            customLabelStyle={{
                                color: theme.colors.onSurface
                            }}
                            loading={companyCtx.loading}
                            onPress={() => {
                                setIsReceiptClockIn(!isStart)
                                handleClockIn()
                                    .then((record) => {
                                        setRecord(record.output.body)

                                        setIsModalReceiptVisible(true)
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    });
                            }}>
                            {isStart ? 'CLOCK-OUT' : 'CLOCK-IN'}
                        </Button>
                        <Text style={{ alignSelf: 'center', color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.body5 }}>
                            {loadingStatus}
                        </Text>
                    </View>
                </View>
            </View>

            <ClockReceiptModal
                isVisible={isModalReceiptVisible}
                isStart={isReceiptClockIn}
                record={record}
                setIsModalVisible={(value) => { setIsModalVisible(value), setIsModalReceiptVisible(value) }}
            />
        </Modal>
    )
}

export default ClockModal;