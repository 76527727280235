import { useState, useContext, useEffect } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native';
import * as Animatable from 'react-native-animatable';

import { useTheme, List } from 'react-native-paper';

import EmptyList from '../components/ui/EmptyList'


import { AuthContext } from '../store/auth-context'
import { UserContext } from '../store/user-context'
import { COLORS, FONTS } from '../constants/theme';


function NotificationScreen() {
  const theme = useTheme();

  const authCtx = useContext(AuthContext);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    userCtx.replaceNotifications(userCtx.notifications.map(item => {
      item.viewed = true
      return item
    }))
  }, [])

  return (
    <>
      {userCtx.notifications?.length > 0 ?
        <ScrollView
          bounces={false}
          style={{
            flex: 1,
            backgroundColor: theme.colors.background
          }}>

          {
            userCtx.notifications.sort((a, b) => { return b.date - a.date }).map((notification, index) => {
              return (
                <List.Item
                  key={`item-${index}`}
                  title={notification.request.content.title}
                  description={notification.request.content.body}
                  titleStyle={{ ...FONTS.h3 }}
                />
              )
            })
          }
        </ScrollView>
        :
        <EmptyList
          source={require('../assets/empty-box.json')}
          title="No notifications"
        />
      }
    </>
  );
}

export default NotificationScreen;