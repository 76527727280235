import { useContext, useState, useEffect } from 'react';
import { Avatar, useTheme, Checkbox, List } from 'react-native-paper';

import { StyleSheet, Text, View } from 'react-native';
import { AuthContext } from '../store/auth-context';
import { ThemeContext } from '../store/theme-context';
import { UserContext } from '../store/user-context';
import QRCode from 'react-native-qrcode-svg';

import { Switch } from 'react-native-paper';
import FormContent from '../components/ui/FormContent';


import { FONTS, SIZES } from '../constants/theme';

function ProfileScreen() {
  const theme = useTheme();

  const authCtx = useContext(AuthContext);
  const themeCtx = useContext(ThemeContext);
  const userCtx = useContext(UserContext);
  
  const [isDarkMode, setIsDarkMode] = useState(themeCtx.theme === "dark");
  const [clockReminder, setClockReminder] = useState(userCtx.clockReminder);

  const handleThemeSwitch = () => {
    setIsDarkMode(!isDarkMode);
    themeCtx.updateTheme()
  }

  const handleClockReminderSwitch = () => {
    console.log(userCtx.user)
    setClockReminder(!clockReminder);
    userCtx.updateClockReminder()
  }

  return (
    <FormContent
      titleContainerStyle={{
        marginTop: '20%'
      }}
    >

      <View
        style={{
          flex: 1,
          alignItems: 'center',
          top: -80,
          //backgroundColor: 'blue'
        }}
      >
        {!!authCtx.user?.attributes.picture ?
          <Avatar.Image 
            size={110}  
            source={{ uri: authCtx.user?.attributes.picture }} />
          :
          <Avatar.Icon
            size={90}
            style={{
              borderColor: 'white',
              borderWidth: 4,
            }}
            icon="account"
          />
        }
        <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.base, fontWeight: 'bold', ...FONTS.h2 }}>
          {userCtx.user?.employee_name + ' ' + userCtx.user?.surname}
        </Text>
        <Text style={{ color: theme.colors.onSurfaceVariant, marginTop: SIZES.base, ...FONTS.h3 }}>
          {userCtx.user?.position?.position_name} - {userCtx.user?.department?.department_name}
        </Text>      
      </View>

      <View
          style={{ 
            flex: 1,
            top: -50,
            alignItems: 'center', 
            marginTop: SIZES.base * 2 
          }}
        >
          <QRCode
            size={130}
            backgroundColor={theme.colors.background}
            color={theme.colors.onBackground}
            value={userCtx.user?.username}
          />
        </View>
      
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          //backgroundColor: 'red'
        }}
      >
        <Text style={{ alignSelf: 'center', color: theme.colors.onBackground, ...FONTS.h3 }}>Dark mode</Text>
        <Switch value={isDarkMode} onValueChange={() => handleThemeSwitch()} />
      </View>
      <Checkbox.Item
          style={{
            marginLeft: -16,
            marginTop: SIZES.base,
          }}
          label={'Remind to clock-in/out'}
          status={clockReminder ? 'checked' : 'unchecked'}
          color={theme.colors.secondary}
          onPress={() => { handleClockReminderSwitch() }}
        />
    </FormContent>
  );
}

export default ProfileScreen;

const styles = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
  },
});
