import { useContext, useEffect } from 'react';
import { useNavigation, useIsFocused } from '@react-navigation/native';

import { List, FAB,Portal,useTheme, Avatar } from 'react-native-paper';

import { View, ScrollView } from 'react-native';
import { AuthContext } from '../../store/auth-context';
import { CompanyContext } from '../../store/company-context';

import { COLORS, SIZES, FONTS } from '../../constants/theme'


function EmployeeListScreen() {
    const theme = useTheme();
    const isFocused = useIsFocused();
    const authCtx = useContext(AuthContext);
    const companyCtx = useContext(CompanyContext);
    const navigation = useNavigation()
    
    useEffect(() => {
      const companyId = authCtx.user.attributes['custom:company_id']
      
      companyCtx.fetchCompany(companyId)
      companyCtx.fetchEmployees(companyId)      
    }, [])

    return (
      <ScrollView 
          style={{
              flex: 1,
              backgroundColor: theme.colors.background,
          }}>
            {
            companyCtx.employees?.map((item, index) => {
              return (
                <List.Item
                  key={`employee-${index}`}
                  title={item.employee_name + " " + item.surname}
                  description={item.email}
                  left={props => <Avatar.Icon size={SIZES.icon + 8} {...props} color={COLORS.grey} icon="account" />}
                  right={props => <List.Icon {...props} icon="pencil" />}
                  onPress={() => { navigation.navigate("Employee", { edit: item }) }}
                  titleStyle={{ ...FONTS.h3 }}
                  descriptionStyle={{ ...FONTS.body4 }}
                />
              )
            })
          }

      <Portal>
        <FAB.Group
          visible={isFocused}
          icon={'plus'}
          actions={[]}
          onStateChange={()=>{}}
          onPress={() => {
            navigation.navigate("Employee")
          }}
          fabStyle={{
            marginBottom: SIZES.padding * 3,
            backgroundColor: theme.colors.primary
          }}
        />
      </Portal>
      </ScrollView>
    );
  
}

export default EmployeeListScreen;