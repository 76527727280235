import { Pressable, StyleSheet } from 'react-native';
import { Badge, } from 'react-native-paper';

import { Ionicons } from '@expo/vector-icons';

function IconButton({ icon, color, size, onPress, badgeVisible = false, badgeLabel }) {
  return (
    <Pressable
      style={({ pressed }) => [styles.button, pressed && styles.pressed]}
      onPress={onPress}
    >
      <>
        {badgeVisible &&
          <Badge style={{ zIndex: 1, position: 'absolute', top: -4, right: -4 }}>{badgeLabel}</Badge>
        }
        <Ionicons style={{ zIndex: 0 }} name={icon} color={color} size={size} />
      </>
    </Pressable>
  );
}

export default IconButton;

const styles = StyleSheet.create({
  button: {
    margin: 8,
    borderRadius: 20,
  },
  pressed: {
    opacity: 0.7,
  },
});
