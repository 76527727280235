import { StyleSheet } from 'react-native';
import { Button as ButtonPaper, ActivityIndicator, useTheme, HelperText } from 'react-native-paper';


import { Colors } from '../../constants/styles';

/**
 * Creates a button object
 *
 * @mode {string} Type: 'text' | 'outlined' | 'contained' | 'elevated' | 'contained-tonal'
 * @buttonColor {string} 
 */
function Button({ 
  children, 
  helperText='',
  onPress, 
  mode='contained',
  buttonColor,
  compact=false,
  customStyle,
  customLabelStyle,
  loading=false,
  disabled=false
 }) {
  const theme = useTheme();

  return (
    <>
    <ButtonPaper 
    compact={compact} 
    mode={mode} 
    onPress={loading ? null : onPress} 
    style={[styles.button, customStyle]} 
    labelStyle={[styles.label, customLabelStyle]} 
    buttonColor={buttonColor}
    loading={loading}
    disabled={disabled}
    >
      {/* {loading ? <ActivityIndicator color={Colors.white} size={20} animating={true} /> : children}  */}
      {children}
    </ButtonPaper>
    {helperText && 
      <HelperText type="info" visible={true}>{helperText}</HelperText>
    }
    </>
  );
}

export default Button;

const styles = StyleSheet.create({
  button: {
    width: '100%',
    marginTop: 14,
  },
  label: {
    // fontSize: 20,
    // fontWeight: 'bold',
    // marginBottom: 8,
  }
});
