import { useContext } from 'react';

import { useNavigation } from '@react-navigation/native';

import {Platform, StyleSheet, View, ScrollView, SafeAreaView, Text} from 'react-native';

import { List, useTheme } from 'react-native-paper';

import { COLORS, FONTS, SIZES } from '../constants/theme';
import { UserContext } from '../store/user-context';
import Toast from 'react-native-root-toast';
import Constants from 'expo-constants';

function MoreScreen() {
  const theme = useTheme();
  const userCtx = useContext(UserContext);

  const navigation = useNavigation();
  const availableMoreScreens = [
    {
      "name": "Employees",
      "items": [
        { "title": "Employees", "description": "Invite and manage your employees", "navigate": "EmployeeList", "icon": "account-group-outline", "permission": "employees", "platforms": ["ios", "android", "web"] },
      ]
    },
    {
      "name": "Organization",
      "items": [
        { "title": "Departments", "description": "Manage your departments", "navigate": "DepartmentList", "icon": "domain", "permission": "departments", "platforms": ["ios", "android", "web"], },
        { "title": "Roles", "description": "Create, edit, and delete roles", "navigate": "PositionList", "icon": "clipboard-account-outline", "permission": "roles", "platforms": ["ios", "android", "web"], },
        { "title": "Shifts", "description": "Define your shifts", "navigate": "ShiftList", "icon": "clock-outline", "permission": "shifts", "platforms": ["ios", "android", "web"], },
        { "title": "Locations", "description": "Manage your job locations", "navigate": "LocationList", "icon": "map-marker-outline",  "permission": "locations", "platforms": ["ios", "android", "web"], },
        { "title": "Kiosks", "description": "Physical devices for in-person clock-in", "navigate": "KioskList", "icon": "tablet-cellphone",  "permission": "kiosks", "platforms": ["ios", "android", "web"], },
      ]
    },
    {
      "name": "Settings",
      "items": [
        { "title": "Company", "description": "Manage your organization settings", "navigate": "CompanySettings", "icon": "office-building-cog-outline", "permission": "company", "platforms": ["ios", "android", "web"], },
        { "title": "Subscription", "description": "Manage your subscription", "navigate": "Subscription", "icon": "credit-card-outline", "permission": "subscription", "platforms": ["android", "web"], },
      ]
    }
  ];

  return (
    <ScrollView
      bounces={false}
      style={{
        flex: 1,
        backgroundColor: theme.colors.background
      }}>

      {
        availableMoreScreens.map((section, index) => {
          return (
            <List.Section
              key={`section-${index}`}
            >
              <List.Subheader>{section.name}</List.Subheader>
              {
                section.items.map((item, index) => {
                  const hasPermission = userCtx.user.is_root_user || (userCtx.user.position && userCtx.user.position.position_permissions && userCtx.user.position.position_permissions.includes(item.permission))
                  const allowedPlatform = item.platforms.includes(Platform.OS)

                  if(!allowedPlatform) return

                  return (
                    <List.Item
                      key={`item-${index}`}
                      title={item.title}
                      description={item.description}
                      left={props => <List.Icon {...props} icon={item.icon} color={COLORS.gray} />}
                      onPress={() => { 
                        hasPermission ? navigation.navigate(item.navigate) : 
                        Toast.show("You don't have permission to access this feature", {
                          duration: Toast.durations.SHORT,
                          position: Toast.positions.BOTTOM,
                        })
                      }}
                      titleStyle={{ color: hasPermission ? theme.colors.onSurface : theme.colors.onSurfaceDisabled, ...FONTS.h3 }}
                      descriptionStyle={{ color: hasPermission ? theme.colors.onSurface : theme.colors.onSurfaceDisabled }}
                    />
                  )
                })
              }
            </List.Section>
          )
        })
      }
      <View
        style={{
          marginTop: SIZES.padding,
          alignItems: 'center',
          flex: 1,
          backgroundColor: Constants.expoConfig.extra.environment == 'development' ? 'red' : theme.colors.background
        }}
      >
        <Text 
          style={{ color: theme.colors.onSurface, ...FONTS.h4 }}
        >
          {Constants.expoConfig.version}
        </Text>
        <Text
          style={{ color: theme.colors.onSurface, ...FONTS.h4 }}
        >
        {'UClock, branch in Ireland'}
        </Text>
        {Constants.expoConfig.extra.environment == 'development' &&
           <Text 
           style={{ color: theme.colors.onSurface, ...FONTS.h4 }}
         >
          {Constants.expoConfig.extra.environment}
        </Text>
        }
      </View>
    </ScrollView>
  );
}

export default MoreScreen;

const styles = StyleSheet.create({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 8,
  },
});
