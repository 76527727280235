import { useContext, useEffect,  useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useTheme } from 'react-native-paper';
import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity, FlatList, ScrollView, Animated } from 'react-native';
import { Ionicons } from '@expo/vector-icons'
import Button from './Button';
import CardHeader from './CardHeader';

import Victory from "../ui/victory"
import VictoryCustomTheme from '../../styles/VictoryCustomTheme';

import { COLORS, SIZES, FONTS } from '../../constants/theme'

function ChartCard({ chartSummary, chartOptions, periodFilterEnabled = true, customContainerStyle }) {
  const theme = useTheme();
  const [selectedSummary, setSelectedSummary] = useState(chartSummary)
  const [selectedOption, setSelectedOption] = useState(chartOptions[0])

  const scrollX = new Animated.Value(0);
  const numberOfCharts = [1, 2, 3];

  function optionOnClickHandler(option) {
    setSelectedOption(option)
  }

  function renderDots() {
    const dotPosition = Animated.divide(scrollX, SIZES.width)

    return (
      <View 
      style={{ 
        height: 30, 
        marginTop: 15,
        
      }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {numberOfCharts.map((item, index) => {
            const opacity = dotPosition.interpolate({
              inputRange: [index - 1, index, index + 1],
              outputRange: [0.3, 1, 0.3],
              extrapolate: 'clamp'
            })

            const dotSize = dotPosition.interpolate({
              inputRange: [index - 1, index, index + 1],
              outputRange: [SIZES.base * 0.8, 10, SIZES.base * 0.8],
              extrapolate: 'clamp'
            })

            const dotColor = dotPosition.interpolate({
              inputRange: [index - 1, index, index + 1],
              outputRange: [COLORS.gray, theme.colors.primary, COLORS.gray],
              extrapolate: 'clamp'
            })


            return (
              <Animated.View
                key={`dot-${index}`}
                opacity={opacity}
                style={{
                  borderRadius: SIZES.radius,
                  marginHorizontal: 6,
                  width: dotSize,
                  height: dotSize,
                  backgroundColor: dotColor
                }}
              />
            )
          })}
        </View>
      </View>
    )
  }

  return (
    <View
      style={{
        backgroundColor: 'red',
        marginHorizontal: SIZES.padding,
        alignItems: 'center',
        borderRadius: SIZES.radius,
        backgroundColor: theme.colors.surfaceVariant,
        ...customContainerStyle
      }
      }>
      {/* Header */}
      <View
        style={{
          flexDirection: 'row',
          marginTop: SIZES.padding,
          paddingHorizontal: SIZES.padding
        }}
      >
        <View style={{ flex: 1 }}>
          <CardHeader
            icon={"notifications"}
            title={selectedSummary?.currency}
            subtitle={selectedSummary?.code}
          />
        </View>
        <View>
          <Text style={{  color: theme.colors.onSurfaceVariant, ...FONTS.h3 }}>{selectedSummary?.amount}</Text>
          <Text style={{ color: COLORS.green, ...FONTS.body3 }}>{selectedSummary?.changes}</Text>
        </View>
      </View>

      {/* Chart */}
      <Animated.ScrollView
        horizontal
        pagingEnabled
        scrollEventThrottle={16}
        snapToAlignment="center"
        snapToInterval={SIZES.width - 40}
        showsHorizontalScrollIndicator={false}
        decelerationRate={0}
        onScroll={Animated.event([
          {
            nativeEvent: {
              contentOffset: { x: scrollX }
            }
          }
        ], { useNativeDriver: false })
        }
      >{
          numberOfCharts.map((item, index) => (

            <View
              key={`chart-${index}`}
              style={{
                marginLeft: index == 0 ? SIZES.base : 0
              }}
            >
              <View style={{ marginTop: -25 }}>
                <Victory.VictoryChart
                  theme={VictoryCustomTheme}
                  height={220}
                  width={SIZES.width - 40}
                >

                  <Victory.VictoryLine
                    style={{
                      data: {
                        stroke: theme.colors.secondary
                      },
                      parent: {
                        border: "1px solid #ccc"
                      },
                    }}
                    data={selectedSummary?.chartData}
                    categories={{
                      x: ["M", "T", "W", "T", "F", "S", "S"],
                      y: ["4h", "8h", "10h"]
                    }}
                  />
                  <Victory.VictoryScatter
                    data={selectedSummary?.chartData}
                    size={7}
                    style={{
                      data: {
                        fill: theme.colors.secondary
                      }
                    }}
                  />
                  <Victory.VictoryAxis
                    style={{
                      grid: {
                        stroke: "transparent"
                      }
                    }}
                  />
                  <Victory.VictoryAxis
                    dependentAxis
                    style={{
                      axis: {
                        stroke: "transparent"
                      },
                      grid: {
                        stroke: "grey"
                      }
                    }}
                  />
                </Victory.VictoryChart>
              </View>
            </View>
          ))

        }
      </Animated.ScrollView>

      {/* Options */}
      {periodFilterEnabled &&
        <View
          style={{
            width: '100%',
            paddingHorizontal: SIZES.padding,
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
          {
            chartOptions.map((option) => {
              return (
                <Button
                  key={`option-${option.id}`}
                  mode={selectedOption.id == option.id ? "contained" : "outlined"}
                  compact={true}
                  customStyle={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 75,
                    height: 40,

                  }}
                  customLabelStyle={{
                    ...FONTS.body5
                  }}
                  onPress={() => optionOnClickHandler(option)}
                >
                  {option.label}
                </Button>
              )
            })
          }
        </View>
      }

      {/* Dots */}
      {renderDots()}
    </View>
  );
}

export default ChartCard;