import { useState } from 'react';
import { Alert, StyleSheet, Text, View } from 'react-native';
import { useRoute } from '@react-navigation/native';
import Toast from 'react-native-root-toast';

import { useTheme } from 'react-native-paper';
import AuthForm from './AuthForm';


import * as Animatable from 'react-native-animatable';

function AuthContent({ isLogin, loading, onAuthenticate, onFederatedSignIn }) {
  const theme = useTheme();
  const route = useRoute();

  const [credentialsInvalid, setCredentialsInvalid] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });

  const isJoining = route.params?.isJoining;

  function submitHandler(credentials) {
    let { email, password, confirmPassword } = credentials;

    email = email.trim().toLowerCase();
    password = password.trim();

    const emailIsValid = email.includes('@');
    const passwordIsValid = password.length >= 8;
    const passwordsAreEqual = password === confirmPassword;

    if (
      !emailIsValid ||
      !passwordIsValid ||
      (!isLogin && (!passwordsAreEqual))
    ) {
      errorMessage = 'Invalid input. Please check your entered credentials.'

      if(!emailIsValid){
        errorMessage = 'Email is invalid.'
      }
      if(!passwordIsValid){
        errorMessage = 'Password is too short. Minimum 8 characters.'
      }
      if((!isLogin && (!passwordsAreEqual))){
        errorMessage = 'Passwords do not match.'
      }

      Toast.show(errorMessage, {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
      });
      setCredentialsInvalid({
        email: !emailIsValid,
        password: !passwordIsValid,
        confirmPassword: !passwordIsValid || !passwordsAreEqual,
      });
      return;
    }
    onAuthenticate({ email, password });
  }

  function federatedHandler() {
    onFederatedSignIn()
  }

  return (
    <View style={{
      backgroundColor: theme.colors.tertiary,
      ...styles.authContent}}>
      <Animatable.View animation="fadeInLeft" delay={500} style={styles.containerHeader}>
        <Text style={styles.message}>
          {isLogin ? isJoining ? 'Join as an Employee' : 'Login' : 'Let\'s get started!'}
        </Text>
      </Animatable.View>
      
      <Animatable.View animation="fadeInUp" style={{ backgroundColor: theme.colors.background, ...styles.containerForm}}>
        <AuthForm 
          isLogin={isLogin}
          loading={loading}
          onSubmit={submitHandler}
          onFederatedSignIn={federatedHandler}
          credentialsInvalid={credentialsInvalid}
        />
      </Animatable.View>
    </View>
  );
}

export default AuthContent;

const styles = StyleSheet.create({
  authContent: {
    flex: 1,
  },
  containerHeader: {
    marginTop: '26%',
    marginBottom: '8%',
    paddingStart: '5%'
  },
  message: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#FFF'
  },
  containerForm: {
    flex:1,
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    paddingStart: '5%',
    paddingEnd: '5%'
  },
});
