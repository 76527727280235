import { useState, useContext, useEffect, createRef, forwardRef, useRef } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, View, Image } from 'react-native';
import Moment from 'react-moment';
import MomentTZ from 'moment-timezone';
import moment from 'moment'
import { Storage } from 'aws-amplify';

import { useTheme, List, Text } from 'react-native-paper';

import FormContent from '../../components/ui/FormContent';


import MapView, {  Marker, Circle, PROVIDER_GOOGLE } from 'react-native-maps';

import { COLORS, SIZES, FONTS } from '../../constants/theme'


function ReportDetailScreen({ route }) {
   //0 in location, 1 not in location, 2 no restriction

  const theme = useTheme();
  const record = route.params?.record;

  const [photoClockIn, setPhotoClockIn] = useState(null);
  const [photoClockOut, setPhotoClockOut] = useState(null);

  useEffect(() => {
    Storage.get(record.record_id + ".jpg", { level: 'protected' })
      .then((imgUrl) => {
        setPhotoClockIn(imgUrl)
      })
    if(record.clock_out){
      Storage.get(record.clock_out.record_id + ".jpg", { level: 'protected' })
      .then((imgUrl) => {
        setPhotoClockOut(imgUrl)
      })
    }
  }, [])

  return (
    <FormContent
            title={"Report detail"}
    >
      <Text style={{ color: theme.colors.onBackground, ...FONTS.h2 }}>
        <Moment format="DD/MM/YYYY" element={Text}>{record.date}
        </Moment>
      </Text>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View>
          <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.padding, ...FONTS.h3 }}>
            Clock-in
          </Text>
          <Moment
            style={{ fontWeight: "bold", color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}
            format="HH:mm" element={Text}>{MomentTZ.tz(record.datetime, 'UTC')}
          </Moment>
        </View>
        {record.clock_out &&
          <>
            <View>
              <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.padding, ...FONTS.h3 }}>
                Clock-out
              </Text>
              <Moment
                style={{ fontWeight: "bold", color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}
                format="HH:mm" element={Text}>{MomentTZ.tz(record.clock_out.datetime, 'UTC')}
              </Moment>
            </View>
            <View>
              <Text style={{ color: theme.colors.onBackground, marginTop: SIZES.padding, ...FONTS.h3 }}>
                Duration
              </Text>
              <Text style={{ fontWeight: "bold", color: theme.colors.onBackground, marginTop: SIZES.base, ...FONTS.h3 }}>
                {moment.duration(record.record_duration, 'seconds').format('HH[h]mm[m]')}
              </Text>
            </View>
          </>
        }
      </View>
      <Text style={{ marginTop: SIZES.padding, color: theme.colors.onBackground, ...FONTS.h3 }}>
        Clock-in location
      </Text>
      <Text style={{ fontWeight: "bold", marginTop: SIZES.base, color: theme.colors.onBackground, ...FONTS.h3 }}>
        {record.location.status == 2 ? "No restriction" : record.location.status == 1 ? "Not in location" : "In location"}
      </Text>
      <View
        style={{
          marginTop: SIZES.base,
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: 180
        }}
      >
        <View
          style={{
            flex: 1.2,
            borderRadius: SIZES.radius,
            marginRight: SIZES.base,
            overflow: 'hidden',
          }}
        >
          <MapView
            region={{
              latitude: parseFloat(record.location.coords.latitude),
              longitude: parseFloat(record.location.coords.longitude),
              latitudeDelta: 0.003,
              longitudeDelta: 0.003,
            }}
            zoomControlEnabled={false}
            scrollEnabled={false}
            rotateEnabled={false}
            zoomTapEnabled={false}
            showsIndoors={false}
            latitude={parseFloat(record.location.coords.latitude)}
            longitude={parseFloat(record.location.coords.longitude)}
            provider={PROVIDER_GOOGLE}
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Marker
              title={"Location"}
              coordinate={{
                latitude: parseFloat(record.location.coords.latitude),
                longitude: parseFloat(record.location.coords.longitude),
              }}
            ></Marker>
            {record.location.location &&
              <Circle
                center={{
                  latitude: parseFloat(record.location.location.latitude),
                  longitude: parseFloat(record.location.location.longitude),
                }}
                radius={parseFloat(record.location.location.radius)}
                fillColor={theme.colors.primary + "90"}
              >
              </Circle>
            }
          </MapView>
        </View>
        <View
          style={{
            flex: 1,
            borderRadius: SIZES.radius,
            overflow: 'hidden',
          }}
        >
          <Image
            style={{ height: '100%', width: '100%', borderRadius: SIZES.radius, }}
            source={{ uri: photoClockIn }}
          />
        </View>
      </View>
      {record.clock_out &&
        <View
          style={{
            marginTop: SIZES.padding,
          }}
        >
          <Text style={{ color: theme.colors.onBackground, ...FONTS.h3 }}>
            Clock-out location
          </Text>
          <Text style={{ fontWeight: "bold", marginTop: SIZES.base, color: theme.colors.onBackground, ...FONTS.h3 }}>
            {record.clock_out.location.status == 2 ? "No restriction" : record.clock_out.location.status == 1 ? "Not in location" : "In location"}
          </Text>
          <View
            style={{
              marginTop: SIZES.base,
              flexDirection: 'row',
              justifyContent: 'space-between',
              height: 180
            }}
          >
            <View
              style={{
                flex: 1.2,
                borderRadius: SIZES.radius,
                marginRight: SIZES.base,
                overflow: 'hidden',
              }}
            >
              <MapView
                region={{
                  latitude: parseFloat(record.clock_out.location.coords.latitude),
                  longitude: parseFloat(record.clock_out.location.coords.longitude),
                  latitudeDelta: 0.003,
                  longitudeDelta: 0.003,
                }}
                zoomControlEnabled={false}
                scrollEnabled={false}
                rotateEnabled={false}
                zoomTapEnabled={false}
                showsIndoors={false}
                latitude={parseFloat(record.clock_out.location.coords.latitude)}
                longitude={parseFloat(record.clock_out.location.coords.longitude)}
                provider={PROVIDER_GOOGLE}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Marker
                  title={"Location"}
                  coordinate={{
                    latitude: parseFloat(record.clock_out.location.coords.latitude),
                    longitude: parseFloat(record.clock_out.location.coords.longitude),
                  }}
                ></Marker>
                {record.clock_out.location.location &&
                  <Circle
                    center={{
                      latitude: parseFloat(record.clock_out.location.location.latitude),
                      longitude: parseFloat(record.clock_out.location.location.longitude),
                    }}
                    radius={parseFloat(record.clock_out.location.location.radius)}
                    fillColor={theme.colors.primary + "90"}
                  >
                  </Circle>
                }
              </MapView>
            </View>
            <View
              style={{
                flex: 1,
                borderRadius: SIZES.radius,
                overflow: 'hidden',
              }}
            >
              <Image
                style={{ height: '100%', width: '100%', borderRadius: SIZES.radius, }}
                source={{ uri: photoClockOut }}
              />
            </View>
          </View>
        </View>
      }


     
    </FormContent>
  );
}

export default ReportDetailScreen;