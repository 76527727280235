import { useContext, useEffect, useState } from 'react';
import { useTheme } from 'react-native-paper';
import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity, FlatList, ScrollView } from 'react-native';
import { Ionicons } from '@expo/vector-icons'
import { Colors as styleColors } from '../../constants/styles';
import MomentTZ from 'moment-timezone';
import Moment from 'react-moment';

import { COLORS, SIZES, FONTS } from '../../constants/theme'

function RecordHistory({ customContainerStyle, history }) {
    const theme = useTheme();

    const renderItem = ({ item }) => {
        return (
            <TouchableOpacity
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingVertical: SIZES.base,
                    
                }}
                onPress={() => console.log(item)}
            >
                 <Ionicons 
                    name="swap-horizontal" 
                    size={30}
                    height={30}
                    color={theme.colors.primary}
                />
                <View style={{ flex: 1, marginLeft: SIZES.radius }}>
                    <Text style={{ color: theme.colors.onSurfaceVariant, ...FONTS.h3 }}>
                        <Moment format="HH:mm" element={Text}>
                            {MomentTZ.tz(item.datetime, 'UTC')}
                        </Moment>
                    </Text>
                    <Text style={{ color: theme.colors.onSurfaceVariant, ...FONTS.body4 }}>
                        <Moment format="DD-MM-YY" element={Text}>
                            {item.date}
                        </Moment>
                    </Text>
                </View>
                <View 
                style={{ 
                    flexDirection: 'row', 
                    height: '100%',
                    alignItems: 'center'
                }}>
                    <Text style={{ color: item.is_start ? COLORS.green : COLORS.red, ...FONTS.h3 }}>{ item.is_start ? 'IN' : 'OUT' }</Text>
                     
                    <Ionicons 
                        name="chevron-forward" 
                        size={30}
                        height={30}
                        color={COLORS.gray}
                    /> 
                </View>
            </TouchableOpacity>
        )
    }

    return (
        <View
            style={{
                marginTop: SIZES.padding,
                marginHorizontal: SIZES.padding,
                padding: 20,
                borderRadius: SIZES.radius,
                backgroundColor: theme.colors.surfaceVariant,
                ...customContainerStyle
            }}
        >
            <Text style={{ color: theme.colors.onSurfaceVariant, ...FONTS.h2 }}>Record history</Text>
            <FlatList
                contentContainerStyle={{ marginTop: SIZES.radius}}
                scrollEnabled={false}
                data={history}
                keyExtractor={item => `${item.record_id}`}
                renderItem={renderItem}
                showsVerticalScrollIndicator={false}
                ItemSeparatorComponent={() => {
                    <View style={{ width: "100%", height: 1, backgroundColor: COLORS.lightGray }} />
                }}
            >

            </FlatList>
        </View>
    )
}

export default RecordHistory;
