import { useContext, useEffect,  useState, useRef } from 'react';
import { useNavigation, useIsFocused } from '@react-navigation/native';
import { useTheme, FAB, Provider, Portal, AnimatedFAB, Avatar,ActivityIndicator } from 'react-native-paper';
import { Ionicons } from '@expo/vector-icons'
import Moment from 'react-moment';
import moment from 'moment'
import { Storage } from 'aws-amplify';
import { Buffer } from "buffer";
import { getDistance } from 'geolib';
import IconButton from '../components/ui/IconButton';
import AlertCard from '../components/ui/AlertCard';
import ChartCard from '../components/ui/ChartCard';
import NoticeCard from '../components/ui/NoticeCard';
import RecordHistory from '../components/ui/RecordHistory';
import ClockModal from '../components/ui/ClockModal';
import FlatListCard from '../components/ui/FlatListCard';
import { useStopwatch  } from 'react-timer-hook';

import { Camera, CameraType } from 'expo-camera';

import { StyleSheet, Text, View, Image, ImageBackground, TouchableOpacity,FlatList, ScrollView, LogBox, SafeAreaView, Platform, Alert } from 'react-native';
import { AuthContext } from '../store/auth-context';
import { CompanyContext } from '../store/company-context';
import { UserContext } from '../store/user-context';

import { Colors as styleColors } from '../constants/styles';
import { COLORS, SIZES, FONTS } from '../constants/theme'
import dummyData from '../constants/dummy'
import { Colors } from '../constants/styles';

import { groupBy } from '../utils/utils'

import StepIndicator from 'react-native-step-indicator';

import * as Location from 'expo-location';

import * as Notifications from 'expo-notifications';

function HomeScreen() {
  const navigation = useNavigation();

  useEffect(() => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  }, [])

  useEffect(() => {
    const companyId = authCtx.user.attributes['custom:company_id']
    const username = authCtx.user.attributes.sub
    
    const fetchInitialData = async () => {
      companyCtx.fetchCompany(companyId)

      return Promise.all([  
        companyCtx.fetchShifts(companyId),
        companyCtx.fetchDepartments(companyId),
        companyCtx.fetchPositions(companyId),
        companyCtx.fetchLocations(companyId),
        companyCtx.fetchEmployees(companyId),
      ]).then(([shifts, departments, positions, locations, employees]) => {
        setEmployees(employees)

        var user = employees.filter(x => x.username == username)[0]

        if (user.shift_id)
          user = { ...user, "shift": shifts.filter(x => x.shift_id == user.shift_id)[0] }
        if (user.department_id)
          user = { ...user, "department": departments.filter(x => x.department_id == user.department_id)[0] }
        if (user.position_id)
          user = { ...user, "position": positions.filter(x => x.position_id == user.position_id)[0] }
        if (user.location_ids)
          user = { ...user, "locations": locations.filter(x => user.location_ids.includes(x.location_id)) }

        userCtx.setUser(user)

        return user
      })
    }

    const fetchRecords = async () => {
      return companyCtx.fetchRecords(companyId, username).then((records) => {
        records = treatRecords(records)
        setLocalRecords(records)
        
        const latestRecord = records ? records.slice(-1) : [];
        if (latestRecord.length > 0) {
          handleClockDuration(latestRecord[0], records)
        }
      });
    }

    const fetchWeeklyData = async () => {
      const fromDate = moment.tz(Moment.globalTimezone).startOf('day').utc().add(-7, 'days').format();
      const toDate = moment.tz(Moment.globalTimezone).endOf('day').utc().format();

      return companyCtx.getRecords(companyId, username, fromDate, toDate, true)
        .then((records) => {
          var recordsByDate = groupBy(records, 'date')
          setWeeklyGroupedRecords(recordsByDate)

         // console.log(records)

          var weeklyDuration = 0;
          records.forEach(record => 
          {
            weeklyDuration += record.record_duration
          })

          var weeklyAverageDuration = weeklyDuration / recordsByDate.length;

          setListCard([
            {
              "id": 1,
              "icon": "time",
              "title": "Average",
              "subTitle": moment.duration(weeklyAverageDuration, 'seconds').format('HH[h]mm[m]'),
              "summary": "Last 7 days",
              "positive": true,
              "code": "You are on track",
              "tooltip": "Average working time per day in the last 7 days"
            },
            {
              "id": 2,
              "icon": "today",
              "title": "Clocked-in",
              "subTitle": recordsByDate.length + " days",
              "summary": "Last 7 days",
              "positive": false,
              "code": recordsByDate.length < 4 ? "Can be improved" : "You are on track",
              "tooltip": "Number of days clocked-in in the last 7 days"
            },
            {
              "id": 3,
              "icon": "time",
              "title": "Average",
              "subTitle": moment.duration(weeklyAverageDuration, 'seconds').format('HH[h]mm[m]'),
              "summary": "Last 7 days",
              "positive": true,
              "code": "You are on track",
              "tooltip": "Average working time per day in the last 7 days"
            },
          ])
        })

        
    }

    async function configurePushNotification(user) {
      const { status } = await Notifications.getPermissionsAsync();
      let finalStatus = status;

      if (finalStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }

      if (finalStatus !== 'granted') {
        Alert.alert('Failed to get push token for push notification!');
        return;
      }

      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: false,
        }),
      });

      const subscription = Notifications.addNotificationReceivedListener(notification => {
        userCtx.updateNotifications(notification)
      });
       
      if (Platform.OS !== 'web')
      {
        const token = await Notifications.getExpoPushTokenAsync()
        if(user.expo_push_token === undefined || user.expo_push_token !== token.data)
        {
          companyCtx.putEmployeeExpoPushToken(companyId, username, token.data)
        }
      }

      if (Platform.OS === 'android') {
        const channel = await Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.DEFAULT,
        });
      }

      return () => subscription.remove()   
    }

    Promise.all([
      fetchRecords(),
      fetchWeeklyData()
    ]).then(() => {
      authCtx.setShowSplashScreen(false)
    })

    fetchInitialData()
      .then((user) => {
        configurePushNotification(user)
        userCtx.scheduleNotifications(user)  
      })

    
  }, [])

  function treatRecords(records) {
    //console.log(records)
		//Case length one and date different than today. This record came from another day.
		//This means the user has not recorded any clock in or out today.
		var date = moment.tz(Moment.globalTimezone).format("YYYY-MM-DD")

    //console.log(date)

    //TODO: Implement line below instead of current one
    //if (records.length === 1 && moment.utc(records[0].datetime).tz(Moment.globalTimezone).format("YYYY-MM-DD") !== date)
		if (records.length === 1 && moment.utc(records[0].datetime).format("YYYY-MM-DD") !== date)
			if (records[0].is_start)
				records[0].disabled = true
			else
				records = []

		//Case first record is a clock out. That means the previous record before this clock out was taken in another day (and completed today).
		//We add a first record with the previous data and show a record icon disabled
		if (records.length > 0 && records[0].is_start === false)
			records.unshift({
				disabled: true,
				is_start: true,
				datetime: records[0].previous_record_datetime,
				record_id: records[0].previous_record_id
			})

		return records
	}

  const authCtx = useContext(AuthContext);
  const companyCtx = useContext(CompanyContext);
  const userCtx = useContext(UserContext);

  const cameraRef = useRef();

  const theme = useTheme();
  const isFocused = useIsFocused();
  const [isExtended, setIsExtended] = useState(true);
  const [trending, setTrending] = useState(dummyData.trendingCurrencies)
  const [listCard, setListCard] = useState([])
  const [duration, setDuration] = useState(0);
  const [counter, setCounter] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasCameraPermission, setHasCameraPermission] = useState(null);
  const [localRecords, setLocalRecords] = useState([]);
  const [weeklyGroupedRecords, setWeeklyGroupedRecords] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [employees, setEmployees] = useState([])
    
  const latestRecord = localRecords ? localRecords.slice(-1) : [];
  var isStart = latestRecord.length > 0 ? latestRecord[0].is_start : false;

  function renderHeader() {
    const notifications = userCtx.notifications.filter(n => n.viewed === false)

    return (
      <View
        style={{
          width: "100%",
          height: 290,  
          marginBottom: SIZES.padding * 4.9,
          ...styles.shadow
        }}
      >
        {/* background image */}
        <View 
        style={{
          flex: 1,
          backgroundColor: theme.colors.tertiary,
          alignItems: 'center'
        }}>
          {/* Header Bar */}
          <View
            style={{
              marginTop: SIZES.padding * 2,
              width: "100%",
              alignItems: 'flex-end',
              paddingHorizontal: SIZES.padding,
            }}
          >
            <IconButton
              badgeVisible={notifications.length > 0}
              badgeLabel={notifications.length}
              icon="notifications-outline"
              size={30}
              color={'white'}
              onPress={() => navigation.navigate('Notification')}
              styles={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            />
          </View>

          {/* Balance */}
          <View
            style={{
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Stopwatch offsetSeconds={duration} isStart={isStart} latestRecord={latestRecord[0]} />
            <Text style={{ marginTop: SIZES.base, color: COLORS.white, ...FONTS.h1 }}>
              You are {isStart ? "clocked-in" : "clocked-out"}
            </Text>

              {/* <Text style={{ marginTop: SIZES.base, color: COLORS.white, ...FONTS.body4 }}>32h15 Last 7 days</Text> */}
          </View>

          <View
            style={{
              width: "100%",
              marginTop: SIZES.padding - 4,
            }}
          >
            {
              renderTimeline()
            }
          </View>                

            {/* Trending */}
          <View
            style={{
              position: 'absolute',
              bottom: '-30%'
            }}
          >
              <FlatListCard
                data={listCard}
              ></FlatListCard>
          </View>
        </View>
      </View>
    )
  }

  function renderNotice() {
    return (
      <NoticeCard></NoticeCard>
    )
  }

  function renderAlertCard() {
      if(userCtx.clockReminder)
        return <></>

      return (
        <AlertCard
          onPress={() => { navigation.navigate("Profile") }}
          customContainerStyle={{ ...styles.shadow }}
        />
    )
  }

  function renderChartCard(){
    return (
      <ChartCard
        chartSummary={trending[0]}
        chartOptions={dummyData.chartOptions}
        customContainerStyle={{ ...styles.shadow }}
        periodFilterEnabled={false}
      />
    )
  }

  function renderRecordHistory() {
    return (
      <RecordHistory
        customContainerStyle={{ ...styles.shadow }}
        history={localRecords}
      />
    )
  }

  function renderTimeline() {
    const records = localRecords !== null ? localRecords : []

    var stepCount = records.length == 0 ? 2 : records.length >= 1 ? 3 : 2;
    var currentPosition = records.length == 0 ? 0 : records.length >= 1 && isStart ? 1 : 2;

    var labels = currentPosition == 0 ? ["Clock-in", "Clock-out"] : currentPosition == 2 ? ["Clock-in", "", "Clock-out"] : ["Clock-in", "Now", "Clock-out"];

    return (
      <StepIndicator
        customStyles={{
          stepIndicatorSize: 25,
          currentStepIndicatorSize: 25,
          separatorStrokeWidth: 2,
          currentStepStrokeWidth: 2,

          stepStrokeWidth: 2,

          stepStrokeCurrentColor: theme.colors.onTertiary,
          stepStrokeFinishedColor: theme.colors.onTertiary,
          stepStrokeUnFinishedColor: COLORS.gray,

          separatorFinishedColor: theme.colors.onTertiary,
          separatorUnFinishedColor: COLORS.gray,

          stepIndicatorCurrentColor: '#ffffff',
          stepIndicatorFinishedColor: COLORS.gray,
          stepIndicatorUnFinishedColor: COLORS.gray,

          stepIndicatorLabelCurrentColor: 'transparent',
          stepIndicatorLabelFinishedColor: 'transparent',
          stepIndicatorLabelUnFinishedColor: 'transparent',

          currentStepLabelColor: COLORS.white,
          labelColor: '#999999',
          labelSize: SIZES.h4,
          currentStepIndicatorLabelFontSize: SIZES.h4,
          stepIndicatorLabelFontSize: SIZES.h4,
        }}
        currentPosition={currentPosition}
        stepCount={stepCount}
        direction={'horizontal'}
        labels={labels}
      />
    )
  }

  function getLocationByDistance(recordLocation, locations) {
		var inLocations = locations.filter(x => {
			var distance = getDistance(
				{
					"latitude": recordLocation.latitude,
					"longitude": recordLocation.longitude
				},
				{
					"latitude": x.latitude,
					"longitude": x.longitude
				})

			return distance <= x.radius
		})

		return (inLocations.length > 0) ? inLocations[0] : undefined
	}

  async function getCurrentPositionAsync(){
    const ANDROID_DELAY_IN_MS = 2 * 3000 // 👈 4s
    const IOS_DELAY_IN_MS = 5 * 1000 // 👈 15s

    const DELAY_IN_MS = Platform.OS === 'ios' ? IOS_DELAY_IN_MS : ANDROID_DELAY_IN_MS

    const MAX_TRIES = 3
    let tries = 1

    let location = null
    let locationError = null

    do {
      try {
        location = await Promise.race([
          delay(DELAY_IN_MS),
          Location.getCurrentPositionAsync({accuracy: Location.Accuracy.Balanced})
        ])

        if (!location) {
          throw new Error('Timeout')
        }
      } catch (err) {
        setLoadingStatus("Retrying geolocation [" + tries + "]...")
        locationError = err
      } finally {
        tries += 1;
      }
    } while (!location && tries <= MAX_TRIES)

    if (!location) {
      const error = locationError ?? new Error('💣 Could not find location')
      
      setLoadingStatus("💣 Could not retrive location. Try again.")
      companyCtx.setLoading(false)

      throw error
    }

    return location
  }

  async function handleClockIn(){
    companyCtx.setLoading(true)

    var imageSrc = null;
    if(companyCtx.company.record_photo)
    {
      setLoadingStatus("Capturing photo...")

      const options = { quality: 0.1, base64: true, skipProcessing: true };
      const data = await cameraRef.current.takePictureAsync(options);
      imageSrc = data.base64;
      await cameraRef.current.pausePreview();
    }

    setLoadingStatus("Loading permissions...")

    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      console.log('Permission to access location was denied');
      // TODO: Alert user that he needs the permission
      return;
    }

    setLoadingStatus("Loading geolocation...")

    let currentPosition = await getCurrentPositionAsync()
    const coordinates = currentPosition.coords;

    setLoadingStatus("Validating location...")

    var username = authCtx.user.attributes.sub
    console.log('Employees: ' + employees.length)

    var employee = employees.filter(employee => employee.username === username)[0]
    var hasLocationRestriction = userCtx.user.locations && userCtx.user.locations.length > 0 ? true : false
    
    var coords = {
      latitude: coordinates.latitude.toString(),
      longitude: coordinates.longitude.toString()
    }
    var location = {
      status: 2, //0 in location, 1 not in location, 2 no restriction
      location: null,
      coords: coords,
    }

    if (hasLocationRestriction) {
      location.status = 1

      var locationByDistance = getLocationByDistance({
        latitude: coords.latitude,
        longitude: coords.longitude,
      }, userCtx.user.locations)

      if (locationByDistance !== undefined) {
        location.status = 0
        location.location = locationByDistance
      }
    }

    var department = null;
    var position = null;
    var superior = null; 
    var shift = null;
    var locations = null;

    if(employee.department_id !== undefined){
      department = companyCtx.departments?.filter(department => department.department_id === employee.department_id)[0]
    }
    if(employee.position_id !== undefined){
      position = companyCtx.positions?.filter(position => position.position_id === employee.position_id)[0]
    }
    if(employee.shift_id !== undefined){
      shift = companyCtx.shifts?.filter(shift => shift.shift_id === employee.shift_id)[0]
    }
    if(employee.location_ids){
      locations = companyCtx.locations?.filter(location => employee.location_ids.includes(location.location_id))
    }
    if (employee.superior_username !== undefined)
      superior = companyCtx.employees?.filter(x => x.username == employee.superior_username)[0] 

    const companyId = authCtx.user.attributes['custom:company_id']

    setLoadingStatus("Submitting record...")

    return companyCtx.postRecord(
      companyId,
      username,
      employee,
      department,
      position,
      shift,
      locations,
      location,
      superior
    ).then((sf_record) => {
      record = JSON.parse(sf_record['output'])
      output = JSON.parse(record['output']['body'])
     
      if (companyCtx.company.record_photo) {
        setLoadingStatus("Uploading photo...")

        const buffer = Buffer.from(imageSrc, 'base64')

        console.log(output['record_id'])
        Storage.put(output.record_id + '.jpg', buffer, {
          level: 'protected',
          contentType: 'image/png'
        })
      }

      companyCtx.fetchRecords(
        companyId, username
      ).then((records) => {
        records = treatRecords(records)
        setLocalRecords(records)

        handleClockDuration(sf_record, records)
      })

      return record;
    })
    .finally(() => {
      companyCtx.setLoading(false)
      setLoadingStatus("")
    })
  }

  function Stopwatch({offsetSeconds, isStart, latestRecord}) {
    
    if (isStart) {  //This means it's an open record still in progress
      durationDiff = moment.duration(moment.utc().diff(moment.utc(latestRecord.datetime))).asSeconds()
      offsetSeconds += durationDiff
    } 

    var date = new Date();
    date.setSeconds(date.getSeconds() + offsetSeconds);
  
    const {
      totalSeconds,
    } = useStopwatch ({ autoStart: isStart, offsetTimestamp: date });

    return (
      <View>
        <Text style={{ color: COLORS.white, ...FONTS.h2 }}>
          {
            // moment.duration(duration, 'seconds').format('HH [hrs] : mm [minutes]')
            moment.duration(totalSeconds, 'seconds').format('HH [hrs] : mm [min] : ss [sec]')
          }
        </Text>
      </View>
    );
  }

  function handleClockDuration(latest_record, records) {
    var totalDuration = 0;

    records.map((record, index) => {
      if (record.clock_out !== undefined)
        totalDuration += record.record_duration
    })

    setDuration(totalDuration)

    return totalDuration
  }

  function renderFabButton() {
    return (
        <AnimatedFAB
          label={isStart ? 'Clock-out' : 'Clock-in'}
          icon={isStart ? 'minus' : 'plus'}
          
          visible={isFocused}
          extended={isExtended}
          iconMode={'dynamic'}
          animateFrom={'right'}
          onPress={() => {
            setIsModalVisible(true)
          }}
          style={{
            backgroundColor: isStart ? theme.colors.error : theme.colors.success,
            bottom: SIZES.padding,
            right: SIZES.padding - 10,
          }}
        />
    )
  }

  function renderModal() {
    var recordPhoto = false;
    if (companyCtx.company !== null) {
      recordPhoto = companyCtx.company.record_photo
    }

    if (isModalVisible && recordPhoto) {
      //Verify whether the company requires front photo
      (async () => {
        const { status } = await Camera.requestCameraPermissionsAsync();
        setHasCameraPermission(status === 'granted');
      })();
    }

    return (
        <ClockModal
          isVisible={isModalVisible}
          enableCamera={recordPhoto}
          isStart={isStart}
          hasCameraPermission={hasCameraPermission}
          handleClockIn={handleClockIn}
          cameraRef={cameraRef}
          loadingStatus={loadingStatus}
          setIsModalVisible={(value) => { setIsModalVisible(value) }}
        />
      )

  }

  function delay(timeInMilliseconds) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), timeInMilliseconds)
    })
  }
 
  const onScroll = ({ nativeEvent }) => {
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;

    setIsExtended(currentScrollPosition <= 100);
  };

  return (
    <View
    style={{
      flex: 1,
      backgroundColor: theme.colors.background
    }}
    >
      <ScrollView 
        onScroll={onScroll} 
        scrollEventThrottle={5}
        bounces={false}
      >
        <View style={{
          flex: 1,
          paddingBottom: SIZES.padding,
          backgroundColor: theme.colors.background
        }}>
          {renderHeader()}
          {renderAlertCard()}
          {renderChartCard()}
          {renderNotice()}
          {renderRecordHistory()}
        </View>
      </ScrollView>
    {renderModal()}
    {renderFabButton()}
    </View>
  );
}

export default HomeScreen;

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
        width: 0,
        height: 4,
    },
    shadowOpacity: 0.30,
    shadowRadius: 4.65,

    elevation: 8,
}
});
